import { getCurrentInstance, ref } from 'vue';

export const useGtm = () => {
	const vm = getCurrentInstance().proxy;
	const modalId = ref(null);
	const openLayerGtm = (id) => {
		modalId.value = id;
		vm.$gtm.trackEvent({
			event: 'popupShown',
			popupName: id
		});
	};
	const closeLayerGtm = () => {
		vm.$gtm.trackEvent({
			event: 'popupClosed',
			popupName: modalId.value
		});
		modalId.value = null;
	};
	return {
		openLayerGtm,
		closeLayerGtm
	};
};
