import Vue from 'vue';
import './plugins/plugins';
import axios from 'axios';
import 'moment';
import 'wicg-inert';
import currencyFilter from './currency-filter/currency-filter';
import numberFilter from './number-filter/number-filter';
import eventBus from './event-bus/event-bus';
import zeroFillFilter from './zero-fill-filter/zero-fill-filter';
import dateFilter from './date-filter/date-filter';
import capitalize from './capitalize/capitalize';
import { initReferer } from './referer/referer';
import { initCampaign } from './campaign/campaign';
import before from './before-directive/before-directive';
import VueCallRequestModalGlue from '@ui-common/vue-call-request-modal-glue';
import VueFormInfo from '@ui-common/vue-form-info';
import VueFormNotification from '@ui-common/vue-form-notification';
import VueTooltip from '@ui-common/vue-tooltip';
import VueButton from '@ui-common/vue-button';
import './svg-contains-polyfill/svg-contains-polyfill';
import androidPhoneHref from './android-phone-href/android-phone-href';
import iphoneHref from './iphone-href/iphone-href';
import rcoSinglePackagePicker from '../../common/components/rco-single-package-picker/rco-single-package-picker';
import rcoSinglePackagePickerButton from '../../common/components/rco-single-package-picker/rco-single-package-picker-button/rco-single-package-picker-button';
import rcoForeignCurrencyAccountPickerButton from '../../common/components/rco-foreign-currency-account-picker-button/rco-foreign-currency-account-picker-button';
import Slayer from '../components/slayer/slayer.vue';

Vue.prototype.env = window.env;
Vue.component('Slayer', Slayer);
Vue.component('vue-form-info', VueFormInfo);
Vue.component('vue-form-notification', VueFormNotification);
Vue.component('vue-tooltip', VueTooltip);
Vue.component('VueButton', VueButton);
Vue.component('rco-single-package-picker', rcoSinglePackagePicker);
Vue.component('rco-single-card-selector-button', rcoSinglePackagePickerButton);
Vue.component('rco-foreign-currency-account-picker-button', rcoForeignCurrencyAccountPickerButton);

Vue.prototype.openModal = (e, modalId, options) => {
	eventBus.$emit('openModal', e, modalId, options);
};
Vue.prototype.closeModal = (modalId, options) => {
	eventBus.$emit('closeModal', modalId, options);
};
Vue.prototype.openOrsi = (orsiFeature) => {
	eventBus.$emit('openOrsi', orsiFeature);
};
Vue.prototype.sendEvent = (event, options) => {
	eventBus.$emit(event, options);
};
axios.defaults.headers.common['Cache-Control'] = 'no-store';
axios.defaults.headers.common.Pragma = 'no-cache';
axios.defaults.headers.common.Expires = 0;
Vue.prototype.$http = axios;
Vue.filter('currency', currencyFilter);
Vue.filter('number', numberFilter);
Vue.filter('date', dateFilter);
Vue.filter('zero', zeroFillFilter);
Vue.filter('capitalize', capitalize);
initReferer();
initCampaign();
Vue.component('vue-call-request-modal-glue', VueCallRequestModalGlue);
Vue.directive('before', before);
Vue.directive('android-phone-href', androidPhoneHref);
Vue.directive('iphone-href', iphoneHref);
Vue.config.ignoredElements = [
	'picture',
	/^call-request-component-/,
	/^call-request-business/,
	'app-webrtc-rco',
	'otp-adatbiztonsagi-kviz',
	'branch-atm-widget-wc',
	'branch-atm-locator-wc'
];
