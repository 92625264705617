SVGElement.prototype.contains = function contains(node) {
	if (!(0 in arguments)) {
		throw new TypeError('1 argument is required');
	}

	do {
		if (this === node) {
			return true;
		}
	} while ((node = node && node.parentNode));

	return false;
};
