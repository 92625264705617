const isInViewport = (element) => {
	let rect = element.getBoundingClientRect();
	let html = document.documentElement;
	return rect.top >= 0 && rect.left >= 0 && rect.bottom <= (window.innerHeight || html.clientHeight) && rect.right <= (window.innerWidth || html.clientWidth);
};
const showIfNotInViewport = (triggerElement, showElement) => {
	if (isInViewport(triggerElement)) {
		showElement.style.display = 'none';
	} else if (!showElement.classList.contains('btn-sticky--hide')) {
		showElement.style.display = 'block';
	}
};
export default {
	bind(el, binding) {
		const id = binding.value;
		let element;
		window.addEventListener('scroll', function () {
			element = document.getElementById(id);
			showIfNotInViewport(element, el);
		});
		element = document.getElementById(id);
		showIfNotInViewport(element, el);
	}
};
