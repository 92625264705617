import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';
import moment from 'moment';
import Vue from 'vue';
Vue.use(VueI18n);
moment.locale(document.documentElement.lang);
const i18n = createI18n({
	locale: document.documentElement.lang
});

export default i18n;
