import Vue from 'vue';

import VueScrollTo from 'vue-scrollto';
import Vuelidate from 'vuelidate';
import VueGtm from 'vue-gtm';
import { mediaQueries } from '../media-query/media-query';
import VueCookie from 'vue-cookie';
import VueModal from '@ui-common/vue-modal';
import VueLiveAnnouncerPlugin from '@ui-common/vue-live-announcer';
import Vuex from 'vuex';
import DomPortal from 'vue-dom-portal';

Vue.use(VueModal);
Vue.use(VueScrollTo);
Vue.use(VueGtm, {
	// debug: true
});
Vue.use(Vuelidate);
Vue.use(VueCookie);
Vue.use(mediaQueries);
Vue.use(DomPortal);
Vue.use(Vuex);
Vue.use(VueLiveAnnouncerPlugin);
