import { computed, ref, readonly } from 'vue';
import slayerApi from '../../../../api/slayer-api';

const STATES = {
	INIT: Symbol('INIT'),
	SUCCESS: Symbol('SUCCESS'),
	ERROR: Symbol('ERROR')
};
export const useLayers = () => {
	const state = ref(STATES.INIT);
	const layers = ref(null);
	const layer = ref(null);
	const getLayers = async () => {
		try {
			const { data } = await slayerApi.getSlayer();
			layers.value = data;
		} catch (err) {
			throw new Error('Could not get layers.json!');
		}
	};
	const getLayer = async (id) => {
		try {
			if (!layers.value) {
				await getLayers();
			}
			state.value = STATES.SUCCESS;
			if (!layers.value[id]) {
				throw new Error('Layer not found!');
			} else {
				layer.value = layers.value[id];
			}
		} catch (err) {
			state.value = STATES.ERROR;
			throw err;
		}
	};
	const resetLayer = () => {
		layer.value = null;
	};
	const technicalError = computed(() => state.value == STATES.ERROR);
	return {
		layer: readonly(layer),
		technicalError: readonly(technicalError),
		getLayer,
		resetLayer
	};
};
