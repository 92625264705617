export default {
	bind(el, binding) {
		const redirect = () => {
			let url = el.getAttribute('href');
			window.location.href = url;
		};
		el.addEventListener('click', (e) => {
			e.preventDefault();
			binding
				.value()
				.then(() => {
					redirect();
				})
				.catch(() => {
					redirect();
				});
		});
	}
};
