import Vue from 'vue';
import { COOKIE_GDPR_LEVEL, GDPR_LEVELS } from '../constants/constants';
const COOKIE_PORTAL_REFERER = 'portal_referer';

export const initReferer = () => {
	let gdprLevel = Vue.cookie.get(COOKIE_GDPR_LEVEL);
	if (gdprLevel == GDPR_LEVELS.HIGH) {
		let refererCookie = Vue.cookie.get(COOKIE_PORTAL_REFERER);
		if (!refererCookie && document.referrer) {
			Vue.cookie.set(COOKIE_PORTAL_REFERER, document.referrer, { path: '/' });
		}
	}
};

export const deleteReferer = () => {
	Vue.cookie.delete(COOKIE_PORTAL_REFERER);
};
