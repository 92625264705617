import Vue from 'vue';
import { mediaQueries } from '../../../../common/js/media-query/media-query';
import disableSpaceScroll from '../../../../common/js/disable-space-scroll/disable-space-scroll';
import eventBus from '../../../../common/js/event-bus/event-bus';
import DomPortal from 'vue-dom-portal';
import { useDirektIbCookieHandler } from '../../../../common/js/composables/direkt-ib-cookie-handler';

Vue.use(DomPortal);
disableSpaceScroll();

export default {
	mediaQueries,
	el: 'header',
	data: {
		ariaHide: false
	},
	setup() {
		const { provideDirektEbValues } = useDirektIbCookieHandler();
		provideDirektEbValues();
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	mounted() {
		eventBus.$on('toggleAriaHidden', (hide) => {
			this.ariaHide = hide;
		});
	}
};
