import i18n from '../lang/i18n';
export default function (value, currency) {
	let fixed = currency == 'HUF' ? 0 : 2;
	value = parseFloat(value);
	if (typeof value != 'undefined' && value != null) {
		return new Intl.NumberFormat(i18n.locale, { minimumFractionDigits: fixed, maximumFractionDigits: fixed }).format(value);
	} else {
		return '';
	}
}
