import { mediaQueries } from '../../../../common/js/media-query/media-query';
import eventBus from '../../../../common/js/event-bus/event-bus';
import tinyUrl from '../../../../applications/tiny-url/tiny-url.vue';
import { defineComponent } from 'vue';
export default defineComponent({
	mediaQueries,
	el: '#footer',
	components: {
		tinyUrl
	},
	data() {
		return { ariaHide: false };
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	mounted() {
		eventBus.$on('toggleAriaHidden', (hide) => {
			this.ariaHide = hide;
		});
	}
});
