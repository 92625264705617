import '../../common/js/common';
import './frame-landing.scss';
import Vue from 'vue';
import header from '../header/header-commons/lib/header-base';
import footer from '../footer/footer-commons/lib/footer-base';
import isInViewport from '../../common/js/is-in-viewport/is-in-viewport';

Vue.directive('is-in-viewport', isInViewport);

/* eslint-disable no-new*/
new Vue(header);
new Vue(footer);
/* eslint-enable*/
