import EventBus from '../../../common/js/event-bus/event-bus';
import rcoApi from '../../../api/rco-api';
export default {
	name: 'rcoSingleCardSelector',
	props: {
		packageId: {
			type: String,
			required: true
		}
	},
	data: () => ({
		model: {
			cardLimit: null,
			cardShoppingLimit: null,
			cardVirtualShoppingLimit: null,
			packageId: null,
			withoutCard: false,
			choosedCardId: null,
			fromLanding: true
		}
	}),
	created() {
		this.init();
		EventBus.$on('singleCardData', () => {
			this.sendData();
		});
	},
	methods: {
		async sendData() {
			try {
				let packageData = Object.assign({}, this.model);
				if (this.model.withoutCard) {
					delete packageData.choosedCardId;
				}
				const response = await rcoApi.sendPackageData(packageData);
				const url = response.data.url;
				window.location.href = url;
				console.log(url);
			} catch (err) {
				EventBus.$emit('technicalError');
			}
		},
		async init() {
			const chosenCard = window.context.rcoSingleCardPickerData.packages[this.packageId].availableCards[0];
			const cards = window.context.rcoSingleCardPickerData.cards[chosenCard];
			this.model = { ...this.model, ...cards, packageId: this.packageId, choosedCardId: chosenCard };
			try {
				await rcoApi.getWebsalesId();
			} catch (err) {
				console.log(err);
			}
		}
	}
};
