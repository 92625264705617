import queryString from 'query-string';
import Vue from 'vue';
const COOKIE_PORTAL_KAMPANY_SOURCE = 'portal_kampany_source';
const COOKIE_PORTAL_KAMPANY_MEDIUM = 'portal_kampany_medium';
const COOKIE_PORTAL_KAMPANY_CAMPAIGN = 'portal_kampany_campaign';
const COOKIE_PORTAL_KAMPANY_TERM = 'portal_kampany_term';
const COOKIE_PORTAL_KAMPANY_CONTENT = 'portal_kampany_content';
export const initCampaign = () => {
	let isUtm = true;
	let parsed = queryString.parse(location.search);
	let campaign = parsed.utm_source;
	if (!campaign) {
		campaign = parsed.otpkampany_source;
		isUtm = false;
	}
	if (campaign) {
		if (isUtm) {
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_SOURCE, parsed.utm_source?.substring(0, 255), { path: '/' });
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_MEDIUM, parsed.utm_medium?.substring(0, 255), { path: '/' });
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_CAMPAIGN, parsed.utm_campaign?.substring(0, 255), { path: '/' });
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_TERM, parsed.utm_term?.substring(0, 255), { path: '/' });
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_CONTENT, parsed.utm_content?.substring(0, 255), { path: '/' });
		} else {
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_SOURCE, parsed.otpkampany_source?.substring(0, 255), { path: '/' });
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_MEDIUM, parsed.otpkampany_medium?.substring(0, 255), { path: '/' });
			Vue.cookie.set(COOKIE_PORTAL_KAMPANY_CAMPAIGN, parsed.otpkampany_campaign?.substring(0, 255), { path: '/' });
		}
	}
};

export const deleteCampaign = () => {
	Vue.cookie.delete(COOKIE_PORTAL_KAMPANY_SOURCE);
	Vue.cookie.delete(COOKIE_PORTAL_KAMPANY_MEDIUM);
	Vue.cookie.delete(COOKIE_PORTAL_KAMPANY_CAMPAIGN);
	Vue.cookie.delete(COOKIE_PORTAL_KAMPANY_TERM);
	Vue.cookie.delete(COOKIE_PORTAL_KAMPANY_CONTENT);
};
